import React from 'react'
import Layout from '../../components/layout/layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeTwo from "../../components/layout/sidemenu/sidemenu_se_two"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import ContentHeader from '../../components/page-sections/content-header'
import ButtonNext from '../../components/buttons/button-next'

import rowImage from '../../queries/images/row-image'

import unit2Icon from '../../images/icons/unit_02_icon.svg'
import CO2Concentration02 from '../../images/student-pages/unit-2/02_co2_concentrations_antartica.gif'
import CO2Concentration03 from '../../images/student-pages/unit-2/03_co2_concentrations_2000_years.gif'


const Unit2Overview = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - Unit 2 Overview" />
    <Container className="mb-5" fluid>
      <Row>
        <Col md="auto">
          <SideBarSeTwo location={props.location.pathname} />
        </Col>
        <Col>
          <CCBreadcrumb
            pathname={props.location.pathname}
            title={'2.0 Overview'}
            replace={props.replace}
          />
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
          <Card>
            <Card.Body>

              <ContentHeader
                icon={ unit2Icon }
                iconAlt="Unit 2 icon"
                studentPageHeader="Unit 2: Carbon Now"
                unitNumber="2"
                studentPageHeaderSub="Overview"
              />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header className="cardHeader">
                      <h2 className="headerFontBlue">Introduction</h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image1.childImageSharp.fluid}
                            alt="Unit 2 overview"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight ml-2 img-fluid d-none d-md-block"
                        fluid={props.data.image1.childImageSharp.fluid}
                        alt="Unit 2 overview"
                      />
                      <p>
                        You did a lot in Unit 1 of <em>Carbon Connections</em>. In that unit, you considered the movement of carbon among different parts of Earth and over many thousands of years. Carbon is stored in land, atmosphere, and oceans.
                      </p>

                      <p>
                        One indicator of past climate is carbon as CO<sub>2</sub> in the atmosphere. When CO<sub>2</sub> levels were high, average temperatures for Earth were high. When CO<sub>2</sub> levels were low, average temperatures for Earth were low. The main concepts from Unit 1 were:
                      </p>

                      <ul>
                        <li>The climate of Earth is connected to the carbon cycle.</li>
                        <li>A number of indicators tell of past climates for Earth.</li>
                        <li>Forcings lead to a response, and sometimes the response has a time lag.</li>
                        <li>The molecule CO<sub>2</sub> is an important greenhouse gas, as well as a key part of the carbon cycle.</li>
                        <li>Models and simulations help you to understand systems and physical processes on Earth.</li>
                      </ul>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ CO2Concentration02 }
                            alt="Carbon dioxide concentrations, Antarctica"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter rounded mb-4 img-fluid d-none d-md-block"
                        src={ CO2Concentration02 }
                        alt="Carbon dioxide concentrations, Antarctica"
                      />

                      <p>
                        At the end of Unit 1, you saw graphs that showed the recent increase in atmospheric CO<sub>2</sub>. The levels were greater than anything in the ice core record. Scientists are studying this large, recent increase in atmospheric CO<sub>2</sub>. More CO<sub>2</sub> in the atmosphere causes an increase in the planet's temperature. But a question remains: "By how much could temperatures increase?" You will explore that later.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ CO2Concentration03 }
                            alt="Carbon dioxide concentrations, Antarctica"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter rounded mb-4 img-fluid d-none d-md-block"
                        src={ CO2Concentration03 }
                        alt="Carbon dioxide concentrations, last 2000 years"
                      />

                      <p>
                        But first, in Unit 2, you will investigate carbon in the present. To better understand climate, it helps to investigate in more detail some of the processes that move carbon around Earth. For example, you have heard of photosynthesis and respiration. You will learn more about these vital natural processes that transfer carbon in the Earth system. Another key player in the carbon cycle and climate are humans. Humans using fossil fuels for energy adds CO<sub>2</sub> to the atmosphere.
                      </p>

                      <p>
                        Maybe you have heard people say that one way to counteract increasing CO<sub>2</sub> in the atmosphere is to plant trees. That will only help if the carbon stays with the wood in the tree. You will investigate issues like these in this unit. The lessons for this unit are in the Unit 2 Organizer. The main concepts you'll learn are:
                      </p>

                      <ul>
                        <li>Processes&mdash;such as photosynthesis and respiration&mdash;transfer carbon among the parts of the Earth system.</li>
                        <li>Humans use a variety of technologies to monitor carbon moving in the Earth system.</li>
                        <li>Models and simulations help you better understand the systems and physical processes that are active on Earth.</li>
                        <li>Fossil fuels are a key part of generating electrical energy for use by humans, but this also leads to increasing CO<sub>2</sub> levels in the atmosphere.</li>
                      </ul>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                          className="rounded img-fluid mb-3 imageBorder"
                          fluid={props.data.image4.childImageSharp.fluid}
                          alt="Unit 2 organizer"
                        />
                        </Col>
                      </Row>
                      <Img
                        className="largeImageCenter imageBorder rounded mb-4 img-fluid d-none d-md-block"
                        fluid={props.data.image4.childImageSharp.fluid}
                        alt="Unit 2 organizer"
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonNext
                ButtonText="2.1 Moving Carbon"
                NextLink="/unit-2/2.1-moving-carbon/"
              />

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default Unit2Overview

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "student-pages/unit-2/01_trees_changing_colors_fall.jpg" }) {
      ...rowImage
    }
    image4: file(relativePath: { eq: "student-pages/unit-2/04_cc_u2_organizer.png" }) {
      ...rowImage
    }
  }
`
